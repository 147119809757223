
@media all and (min-width: 480px) { 
    .Login { 
      padding: 60px 0; 
      background-color: white !important;
    } 
   
    .Login form { 
      margin: 0 auto; 
      max-width: 33%; 
      background-color: rgb(0, 100, 164);
      color: white;
    } 

    .Login form button {
      background-color: #FFD200 !important;
      border-color: #FFD200 !important;
      color: rgb(0, 100, 164) !important;
    }
} 