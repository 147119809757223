.card {
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
}
.required-field::after {
    content: "*";
    color: red;
}
#redAsterisk {
    color: red;
}
.navbar-brand {
    color: #FFD200 !important;
}
.nav-link {
    color: white !important;
}
.jumbotron {
  padding: 0px !important;
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.10)), url(./header.jpg);
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  height: 350px;
}
.jumbotron h2 {
    color: #FFD200;
}
.jumbotron p {
  font-weight: bold;
}
.jumbotron a {
  color: #FFD200;
}
.Login {
  padding: 0px !important;
}
.Login a {
  color: #FFD200;
}
.btn-primary {
    background-color: #0064A4 !important;
}
.footer img {
  max-width: 20%;
  max-height: 60px;
}
.footer {
 padding-top: 10px;
}
.contentPage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.10)), url(./header.jpg);
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-size: cover;
}
@media (min-width: 576px){
  .jumbotron {
    padding: 0px !important;
  }
  .footer img {
    max-width: 30%;
    max-height: 85px;
  }
}